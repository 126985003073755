import { createRouter, createWebHistory } from 'vue-router'
import AuthHandler from '@/plugins/AuthHandler'

const BasicView = { template: '<router-view></router-view>' }

const routes = [
    {
        path: '/',
        meta: { requiresAuth: false },
        beforeEnter: (to, from, next) => {
            const domain = AuthHandler.getDomainFromRoute(to)
            if (domain !== null) {
                return next({ name: 'bonjour', params: { domain } })
            }
            next({ name: 'login' })
        },
        component: BasicView
    },
    {
        path: '/create',
        name: 'create-account',
        meta: { requiresAuth: false },
        component: () => import(/* webpackChunkName: "Create" */ '@/views/Create')
    },
    {
        path: '/invite/:token',
        name: 'invite-member',
        meta: { requiresAuth: false },
        component: () => import(/* webpackChunkName: "Create" */ '@/views/Invited')
    },
    {
        // Basic view without a complex surrounding
        path: '/auth',
        component: BasicView,
        redirect: { name: 'login' },
        meta: { requiresAuth: false },
        children: [
            {
                path: 'login',
                name: 'login',
                meta: { requiresAuth: false },
                component: () => import(/* webpackChunkName: "Auth" */ '@/views/auth/Login')
            },
            {
                path: 'lost-password',
                name: 'lost-password',
                meta: { requiresAuth: false },
                component: () => import(/* webpackChunkName: "Auth" */ '@/views/auth/LostPassword')
            },
            {
                path: 'token/:token', // No need for a name here, it should never be called directly
                component: () => import(/* webpackChunkName: "Auth" */ '@/views/auth/LoginViaToken')
            },
            {
                path: 'token/:token/change-password', // No need for a name here, it should never be called directly
                component: () => import(/* webpackChunkName: "Auth" */ '@/views/auth/ChangePassword')
            }
        ]
    },
    {
        path: '/validate/:token', // No need for a name here, it should never be called directly
        meta: { requiresAuth: false },
        component: () => import(/* webpackChunkName: "Auth" */ '@/views/auth/ValidateToken')
    },
    {
        // Concrete view
        path: '/:domain',
        component: () => import('@/views/Interface'),
        redirect: to => {
            return { name: 'conversations-inbox', params: { domain: to.params.domain } }
        },
        beforeEnter (to, from, next) {
            AuthHandler.setSlug(to.params.domain)
            next()
        },
        children: [
            {
                name: 'bonjour',
                path: 'bonjour',
                component: () => import('@/views/Bonjour.vue')
            },
            {
                path: 'conversations/',
                name: 'conversations',
                component: () => import('@/views/conversations/Listing.vue'),
                children: [
                    {
                        path: 'inbox',
                        name: 'conversations-inbox',
                        redirect: to => ({ name: 'conversations', query: { assigned: 'me+none', is: 'opened', order: 'priority' } })
                    },
                    {
                        path: 'waiting',
                        redirect: to => ({ name: 'conversations', query: { is: 'opened', direction: 'in', order: 'priority' } })
                    },
                    {
                        path: 'all',
                        redirect: to => ({ name: 'conversations', query: { order: 'priority' } })
                    }
                ]
            },
            {
                path: 'conversations/view/:id',
                name: 'conversations-details',
                component: () => import('@/views/conversations/Details.vue'),
                props: (route) => {
                    // We do this in that order (instead of checking for "i" first) because 99% of the time, we'll load valid tickets
                    let conversationId = Number.parseInt(route.params.id, 10)
                    if (Number.isNaN(conversationId)) {
                        conversationId = 0
                        if (route.params.id.startsWith('i') && route.params.id.length === 2) {
                            // Between i1 & i9
                            conversationId = route.params.id
                        }
                    }

                    return { id: conversationId }
                }
            },
            {
                path: 'settings',
                component: () => import('@/views/settings/Base'),
                children: [
                    // Onboarding
                    {
                        path: 'onboarding',
                        name: 'settings-onboarding',
                        component: () => import(/* webpackChunkName: "Account_settings" */ '@/views/settings/Onboarding')
                    },
                    // Account Settings
                    {
                        path: 'account/general',
                        name: 'settings-account-general',
                        component: () => import(/* webpackChunkName: "Account_settings" */ '@/views/settings/account/General')
                    },
                    {
                        path: 'account/security',
                        name: 'settings-account-security',
                        component: () => import(/* webpackChunkName: "Account_settings" */ '@/views/settings/account/Security')
                    },
                    {
                        path: 'account/notifications',
                        name: 'settings-account-notifications',
                        component: () => import(/* webpackChunkName: "Account_settings" */ '@/views/settings/account/Notifications')
                    },
                    // Organization
                    {
                        path: 'organization/general',
                        name: 'settings-organization-general',
                        component: () => import(/* webpackChunkName: "Organization_settings" */ '@/views/settings/organization/General')
                    },
                    {
                        path: 'organization/hours',
                        name: 'settings-organization-business-hours',
                        component: () => import(/* webpackChunkName: "Organization_settings" */ '@/views/settings/organization/Hours')
                    },
                    {
                        path: 'organization/team',
                        name: 'settings-organization-team',
                        meta: { role: 'teammate.*' },
                        component: () => import(/* webpackChunkName: "Organization_settings" */ '@/views/settings/organization/Team')
                    },
                    {
                        path: 'organization/snippets',
                        name: 'settings-organization-snippets',
                        meta: { role: 'snippet.*' },
                        component: () => import(/* webpackChunkName: "Organization_settings" */ '@/views/settings/organization/Snippets')
                    },
                    {
                        path: 'organization/rules',
                        name: 'settings-organization-rules',
                        meta: { role: 'rule.*' },
                        component: () => import(/* webpackChunkName: "Organization_settings" */ '@/views/settings/organization/Rules')
                    },
                    {
                        path: 'organization/custom-data',
                        name: 'settings-organization-data',
                        component: () => import(/* webpackChunkName: "Organization_settings" */ '@/views/settings/organization/CustomData')
                    },
                    {
                        path: 'organization/knowledge',
                        redirect: { name: 'settings-organization-knowledge' },
                        // meta: { role: 'rule.*' },
                        component: () => import(/* webpackChunkName: "Organization_kb" */ '@/views/settings/organization/knowledgebase/Base'),
                        children: [
                            {
                                path: '',
                                name: 'settings-organization-knowledge',
                                component: () => import(/* webpackChunkName: "Organization_kb" */ '@/views/settings/organization/knowledgebase/Articles')
                            },
                            {
                                path: 'settings',
                                name: 'settings-organization-knowledge-settings',
                                component: () => import(/* webpackChunkName: "Organization_kb" */ '@/views/settings/organization/knowledgebase/Settings')
                            }
                        ]
                    },
                    {
                        path: 'organization/channels',
                        name: 'settings-organization-channels',
                        meta: { role: 'channel.*' },
                        component: () => import(/* webpackChunkName: "Organization_settings" */ '@/views/settings/organization/Channels')
                    },
                    {
                        path: 'organization/integrations',
                        name: 'settings-organization-integrations',
                        meta: { role: 'organization.update' },
                        component: () => import(/* webpackChunkName: "Organization_settings" */ '@/views/settings/organization/Integrations')
                    },
                    {
                        path: 'organization/tags',
                        name: 'settings-organization-tags',
                        meta: { role: 'tag.*' },
                        component: () => import(/* webpackChunkName: "Organization_settings" */ '@/views/settings/organization/Tags')
                    },
                    {
                        path: 'organization/billing',
                        name: 'settings-organization-billing',
                        meta: { role: 'organization.update' },
                        component: () => import(/* webpackChunkName: "Organization_settings" */ '@/views/settings/organization/Billing')
                    },
                    {
                        path: 'organization/blocked',
                        name: 'settings-organization-blocked',
                        component: () => import(/* webpackChunkName: "Organization_settings" */ '@/views/settings/organization/Blocked')
                    },
                    {
                        path: 'organization/insights/inbox',
                        name: 'settings-organization-insights-inbox',
                        component: () => import(/* webpackChunkName: "Organization_settings" */ '@/views/settings/organization/insights/Inbox')
                    },
                    {
                        path: 'organization/insights/kb',
                        name: 'settings-organization-insights-kb',
                        component: () => import(/* webpackChunkName: "Organization_settings" */ '@/views/settings/organization/insights/Kb')
                    }
                ]
            }
        ]
    },
    {
        path: '/:pathMatch(.*)*',
        beforeEnter (to, from, next) {
            // We try to parse:
            let exists = false
            let domain = null
            if (to.params.pathMath.length > 0) {
                domain = to.params.pathMatch[0]
                exists = ['create', 'invite', 'auth', 'validate'].indexOf(domain) > -1
            }

            if (exists) {
                return next({
                    name: 'bonjour',
                    params: { domain }
                })
            }
            next({ name: 'login ' })
        }
    }
]

const router = createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,
    linkActiveClass: 'active',
    routes,
    scrollBehavior (to, from, savedPosition) {
        if (to.meta.basename) {
            return
        }

        return { left: 0, top: 0 }
    }
})

router.onError(error => {
    /**
     * This needs to be used with caution as it may end up in indefinite reload loop.
     * One option to avoid this, you can store the latest revision in client side and
     * reload only if the revision is outdated compared to server.
     */
    if (/loading chunk \d* failed./i.test(error.message)) {
        window.location.reload()
    }
})

export default router
